import axios from "axios";
import router from "@/router";

const api = axios.create({
    // baseURL: "http://127.0.0.1:8000/",
    // baseURL: "https://mtexdynamis.ddns.net/",
    baseURL: process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000/" : "https://mtexdynamis.ddns.net/",
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",

        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        // 'Authorization': `Bearer 6|ONqlo215CHCtZbiSsyicPzhaPCow48ZcuKCVYgZp`
        // 'Authorization': `Bearer ` + localStorage.getItem("token"),
    },
    withCredentials: true,
});

api.interceptors.request.use(
    (config) => {
        // console.log('rejeicao de loginxxxxx000')
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // console.log('rejeicao de loginxxxxx111')
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => response,
    (error) => {
        // console.log('rejeicao de response de login')
        // console.log(error)
        if (error.response.status === 401) {
            localStorage.removeItem('token')
            router.push({ name: 'Login'})
        }

        return Promise.reject(error);
    }
);


export default api;

<template>
    <div class="layout-config-wrapper">
        <a href="#" class="layout-config-button" id="layout-config-button" @click="toggleConfigurator">
            <i class="pi pi-comments" ></i>
        </a>


        <div :class="containerClass">
            <h5>Tipo de Menu</h5>
            <div class="field-radiobutton">
                <RadioButton name="menuMode" value="static" v-model="d_menuMode" id="mode1" @change="changeMenuMode('static')"></RadioButton>
                <label for="mode1">Estático</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton name="menuMode" value="slim" v-model="d_menuMode" id="mode2" @change="changeMenuMode('slim')"></RadioButton>
                <label for="mode2">Recolhido</label>
            </div>

            <h5>Layout</h5>
            <div class="field-radiobutton">
                <RadioButton name="colorScheme" value="light" v-model="d_colorScheme" id="theme1" @change="changeColorScheme('light')"></RadioButton>
                <label for="theme1">Claro</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton name="colorScheme" value="dark" v-model="d_colorScheme" id="theme2" @change="changeColorScheme('dark')"></RadioButton>
                <label for="theme2">Escuro</label>
            </div>

            <h5>Cor de Layout</h5>
            <div class="layout-themes" v-if="$appState.colorScheme === 'light'">
                <div v-for="layout in layoutColors" :key="layout.name">
                    <a style="cursor: pointer" @click="changeLayoutColor(layout.name)" :title="layout.name" :style="{ 'background': layout.color }">
                        <i class="pi pi-check" v-if="layoutColor === layout.name"></i>
                    </a>
                </div>
            </div>
            <div v-else>
                <p>As cores de layout estão disponíveis apenas no modo claro, pois superfícies grandes podem emitir muito brilho no modo escuro.</p>
            </div>

<!--            <h5>Input Style</h5>-->
<!--            <div class="field-radiobutton">-->
<!--                <RadioButton id="input_outlined" name="inputstyle" value="outlined" :modelValue="inputStyle" @update:modelValue="onChange" />-->
<!--                <label for="input_outlined">Outlined</label>-->
<!--            </div>-->
<!--            <div class="field-radiobutton">-->
<!--                <RadioButton id="input_filled" name="inputstyle" value="filled" :modelValue="inputStyle" @update:modelValue="onChange" />-->
<!--                <label for="input_filled">Filled</label>-->
<!--            </div>-->

<!--            <h5>Ripple Effect</h5>-->
<!--            <InputSwitch :modelValue="rippleActive" @update:modelValue="onRippleChange"  />-->

            <h5>Cor Primária</h5>
            <div class="layout-themes">
                <div v-for="t in componentThemes" :key="t.name">
                    <a style="cursor: pointer" @click="changeTheme(t.name)" :title="t.name" :style="{ 'background-color': $appState.colorScheme === 'light' ? t.color1 : t.color2 }">
                        <i class="pi pi-check" v-if="theme === t.name"></i>
                    </a>
                </div>
            </div>

            <h5>Chamado de Suporte</h5>
            <Textarea id="obs" rows="4" placeholder="breve descrição do problema" />

            <Button icon="pi pi-comment" label="Abrir novo chamado" class="mt-4">
            </Button>


        </div>
    </div>
</template>

<script>
export default {
    emits: ['menu-mode-change','color-scheme-change', 'layout-color-change', 'theme-change'],
    props: {
        menuMode: {
            type: String,
            default: null
        },
        lightMenu: {
            type: Boolean,
            default: null
        },
        inlineUser: {
            type: Boolean,
            default: null
        },
        isRTL: {
            type: Boolean,
            default: null
        },
        layoutColor: {
            type: String,
            default: null
        },
        topbarColors: {
            type: Array,
            default: null
        },
        theme: {
            type: String,
            default: null
        },
        themes: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            active: false,
            d_menuMode: this.menuMode,
            d_lightMenu: this.lightMenu,
            d_inlineUser: this.inlineUser,
            d_isRTL: this.isRTL,
            d_colorScheme: this.$appState.colorScheme,
            componentThemes: [
                {name: 'indigo', color1: '#4C63B6', color2 : '#6A7EC2'},
                {name: 'blue', color1: '#1992D4', color2 : '#3BABE8'},
                {name: 'green', color1: '#27AB83', color2 : '#44D4A9'},
                {name: 'deeppurple', color1: '#896FF4', color2 : '#B1A0F8'},
                {name: 'orange', color1: '#DE911D', color2 : '#E8AB4F'},
                {name: 'cyan', color1: '#00B9C6', color2 : '#58CDD5'},
                {name: 'yellow', color1: '#F9C404', color2 : '#FDDD68'},
                {name: 'pink', color1: '#C74B95', color2 : '#D77FB4'},
                {name: 'purple', color1: '#BA6FF4', color2 : '#D1A0F8'},
                {name: 'lime', color1: '#84BD20', color2 : '#A3D44E'},
            ],
            layoutColors: [
                {name: 'white', color: '#ffffff'},
                {name: 'blue', color: 'linear-gradient(147.38deg, #4C96B6 0%, #19496C 100%)'},
                {name: 'cyan', color: 'linear-gradient(147.38deg, #4CB6A3 0%, #19536C 100%)'},
                {name: 'deepblue', color: 'linear-gradient(147.38deg, #4C63B6 0%, #19216C 100%)'},
                {name: 'purple', color: 'linear-gradient(147.38deg, #9E768F 0%, #656C98 100%)'},
                {name: 'yellow', color: 'linear-gradient(147.38deg, #C57F6A 0%, #DABE67 100%)'},
                {name: 'deeppurple', color: 'linear-gradient(147.38deg, #684789 0%, #647DEE 100%)'},
                {name: 'orange', color: 'linear-gradient(147.38deg, #BD9279 0%, #BE5757 100%)'},
                {name: 'green', color: 'linear-gradient(147.38deg, #45947A 0%, #A6BF5D 100%)'},
                {name: 'mauve', color: 'linear-gradient(147.38deg, #455B94 0%, #BFAA5D 100%)'},
                {name: 'dusk', color: 'linear-gradient(147.38deg, #7B3F81 0%, #5DB3BF 100%)'},
                {name: 'ocean', color: 'linear-gradient(147.38deg, #455B94 0%, #90B967 100%)'},
                {name: 'deepgreen', color: 'linear-gradient(147.38deg, #767C50 0%, #344B6F 100%)'},
            ]
        }
    },
    watch: {
        $route() {
            if (this.active) {
                this.active = false;
                this.unbindOutsideClickListener();
            }
        },
        menuMode(newValue) {
            this.d_menuMode = newValue;
        },
        lightMenu(newValue) {
            this.d_lightMenu = newValue;
        },
        inlineUser(newValue) {
            this.d_inlineUser = newValue;
        },
        isRTL(newValue) {
            this.d_isRTL = newValue;
        }
    },
    outsideClickListener: null,
    methods: {
        toggleConfigurator(event) {
            this.active = !this.active;
            event.preventDefault();

            if (this.active)
                this.bindOutsideClickListener();
            else
                this.unbindOutsideClickListener();
        },
        hideConfigurator(event) {
            this.active = false;
            this.unbindOutsideClickListener();
            event.preventDefault();
        },
        onChange(value) {
            this.$primevue.config.inputStyle = value;
        },
        onRippleChange(value) {
            this.$primevue.config.ripple = value;
        },
        changeMenuMode(menuMode){
            this.$emit('menu-mode-change', menuMode);
        },
        changeColorScheme(colorScheme) {
            this.$emit('color-scheme-change', colorScheme);
        },
        changeLayoutColor(layoutName) {
            this.$emit('layout-color-change', layoutName);
        },
        changeTheme(theme) {
            this.$emit('theme-change', theme);
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.active && this.isOutsideClicked(event)) {
                        this.active = false;
                    }
                };
                document.addEventListener('click', this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        isOutsideClicked(event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
        }
    },
    computed: {
        containerClass() {
            return ['layout-config', {'layout-config-active': this.active}];
        },
        rippleActive() {
            return this.$primevue.config.ripple;
        },
        inputStyle() {
            return this.$primevue.config.inputStyle;
        }
    }
}
</script>

import api from '../axios'

export default class UtilsService {

    getssuserlog() {
        return api.get('api/ssusuariolog' ).then((res) => res.data);
    }



}
